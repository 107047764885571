<template class='chat-rooms'>
                <div>
                    
                <!---/Left chat list -->
                <v-navigation-drawer permanent stateless left v-model="drawer" class="flex-shrink-0">
                    <div class="px-3 border-bottom"><v-text-field placeholder="Поиск" class="mb-0 mt-0 " v-model="searchString"></v-text-field></div>
                    <div class="vs-scrollable" v-bar  style="height:300px">
                    <v-list nav v-if="!searchString">
                        <v-list-item v-for="room of rooms" :key="room.id" @click="$emit('open-room', room.id);" v-bind:class="{ 'grey lighten-2 rounded': room.lastMessage && room.lastMessage.read==0 }">
                            <v-avatar size="42" class="mr-3">
                                <img :src="avatar(room.data.avatar)" v-if="room.data && room.data.avatar"/>
                                <v-icon size="42" v-else>mdi-account</v-icon>
                            </v-avatar>
                            <v-list-item-content>
                                <v-list-item-title>
                                    <h4>{{name(room)}}</h4>
                                    <v-chip  color="primary" v-if="room.lastMessage.from==$auth.user().profile.user_id" class="pa-1 mr-2" style="height:auto;">Вы:</v-chip><span class="caption">{{convMes(room.lastMessage.text,room.lastMessage.from)}}</span>
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>

                    </v-list>
                        <v-list nav v-else>
                        <v-list-item v-for="user of searchUsers" :key="user.id" @click="startChat(user.id)">
                            <v-avatar size="42" class="mr-3"><img v-if="user.avatar" :src="user.avatar" /><v-icon v-else>mdi-account</v-icon></v-avatar>
                            <v-list-item-content>
                                <v-list-item-title>
                                    <h4>{{user.name}} </h4>
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        </v-list>
                    </div>
                </v-navigation-drawer>
                </div>
</template>






<script>
const debounce = function (f, ms) {
  let timer = null;
  return function (...args) {
      const onComplete = () => {
          f.apply(this, args);
          timer = null;
      }
      if (timer) {
          clearTimeout(timer);
      }
      timer = setTimeout(onComplete, ms);
  };
}
//  Import axios

export default {
  name: 'chat-rooms',
  components: {
  },
  data () {
    return {
      drawer:null,
      rooms: [],
      errors: [],
      busy: false,
      searchString:null,
      page: 1,
      searchUsers: [],
      new_dialog_with: null,
      users:[],
      no_group_ava: 'https://linksys.i.lithium.com/t5/image/serverpage/image-id/16800i3E8B46D91CD0CF26/image-size/original?v=1.0&px=-1'
    }
  },
  watch: {
    searchString () { this.search() },
    '$store.state.readRoom' () {
        console.log(this.$store.state.readRoom.id)
        if(this.rooms && this.$store.state.readRoom.id){
            var room=this.rooms.find( el=> el.id==this.$store.state.readRoom.id)
            if(room){
                room.lastMessage.read=1
            }
        }
    },
    '$store.state.lastMessage' () {
        var index = this.rooms.findIndex( x => x.id == this.$store.state.lastMessage.lastMessage.room_id )
        if(index!==-1){
            this.rooms.unshift(...this.rooms.splice(index,1));
            this.rooms[0].lastMessage = this.$store.state.lastMessage.lastMessage;
            if(this.$store.state.open_room_id!==this.rooms[0].id)
            {
               this.rooms[0].read=0
            }
        }
        else
        {
            if(this.$store.state.lastMessage.data.user_id==this.$auth.user().profile.user_id)
            {
                this.$store.state.lastMessage.data=this.$store.state.lastMessage.target
            }
            this.rooms.unshift(this.$store.state.lastMessage)
            if(this.$store.state.lastMessage.lastMessage.room_id==this.$store.state.open_room_id)
            {
                this.setRead(this.$store.state.open_room_id)
            }
            
        }
    }
        //this.rooms.map(mapObj => mapObj['name']).indexOf(obj['name']) === pos }
        //$state.messages.room_id this.rooms.unshift(...this.rooms.splice(3,1));
  },
  created(){
      this.fetchData()
  },
  // Fetches posts when the component is created.
  methods: {
      convMes(mes,from=null){
                          if(from && from==this.$auth.user().id){
                var res=mes.split('[/forTecher]')
               mes=res[0]
               if(res[1]){
                   mes=res[1]
               }
                } else {
                    mes=mes.replace('[/forTecher]', '');
                    mes=mes.replace('[forTecher]', '');
                }
                return mes
      },
    shuffle: function () {
        this.rooms.unshift(...this.rooms.splice(3,1));
     //this.rooms = _.shuffle(this.rooms)
    },
    closeRoom(room_id,index){
        this.$http.post('chat/close-room?room_id='+room_id).then(() => {
        this.rooms.splice(index,1)
      })

    },
    setRead(room_id){
        this.$http.post('chat/room-read?room_id='+room_id).then(() => {
      })

    },
    newPublicRoom (name,id){
            var obj={id:id,
            message: JSON.stringify({message:null}),
            data: {avatar: null, name: name, type:'public'},
            from:null,
            read:JSON.stringify([])}
            this.rooms.unshift(obj)
    },
    avatar(avatar)
    {
        if(avatar)
        {
            return avatar
        }
        return null
    },
    name(room)
    {
        if(room.data && room.data.name)
        {
          return room.data.name +" " +room.data.last_name
        }
        if(room.private==1)
        {
            return 'Без имени'
        }
        else
        {
            return 'Групповой чат'
        }
    },
    search: debounce(function () {
      this.fetchUsers()
    }, 500),
    fetchData () {
            this.page=1
            this.rooms=[]
            this.infiniteId += 1;
      this.$http.post('chat/rooms-list?page=' + this.page, 
          {search: this.searchString}
        ).then(( response ) => {
        if (response.data.length) {

          this.rooms.push(...response.data)
        }
      })
    },
    infiniteRooms ($state) {
         this.$http.post('chat/rooms-list?page=' + this.page, 
          {search: this.searchString}
        ).then(( response) => {
        if (response.data.length) {
          this.page += 1;
          this.rooms.push(...response.data)
          $state.loaded();
        } else {
          $state.complete();
        }
      })
    },
    searchUser: debounce(function () {
      this.fetchUsers()
    }, 500),
    fetchUsers() {
      this.searchUsers=[]
      this.$http.get('chat/user-search?q='+this.searchString+'&page=1').then(({ data }) => {
        if (data.length) {
          this.usersPage += 1;
          this.searchUsers.push(...data);
        }
      })
    },
    startChat(user_id){
       this.$http.post('chat/get-room?user_id='+user_id).then(({ data }) => {
        this.$store.state.open_room_id=data
      })
    }
  }
}
</script>
<style scoped>

    .flip-list-move {
  transition: transform 1s;
}
  .unread {
    background-color: #f0f0f0;
    border-radius: 5px;
}

.people-list ul {
  padding: 20px;
}
.people-list ul li {
  padding-bottom: 20px;
}
.people-list img {
  float: left;
  margin-right: 10px;
}
.people-list .about {
  float: left;
  margin-top: 8px;
}
.people-list .about {
  padding-left: 8px;
}
.people-list .status {
  color: #92959e;
}
.people-list input {
  //border-radius: 3px;
  //border: none;
  //padding: 14px;
  //color: white;
  //background: #6a6c75;
  //width: 70%;
  //font-size: 14px;
}

.online {
  color: #86bb71;
}

.offline {
  color: #e46050;
}
.start_dialog {
    max-height: 110px;
    border-bottom: 0.5px solid #c1c1c1;  
    padding:5px;
    margin-bottom: 3px;
}
 
  .widget-followers-controls {
    float: right;
    bottom: 20px;
    position: absolute;
    right: 11px;
    border-radius: 10px;
}
</style>
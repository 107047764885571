import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'
import Vuebar from 'vuebar'
import './plugins/base'
import 'leaflet/dist/leaflet.css';
const VueScrollTo = require('vue-scrollto')
Vue.use(VueScrollTo)
import VueTour from 'vue-tour'
        require('vue-tour/dist/vue-tour.css')
Vue.use(VueTour)
import VueNativeSock from 'vue-native-websocket'
Vue.use(VueNativeSock, 'wss://api.learn.mchs.ru/wss', {reconnection: true, store: store})       
import VueChatScroll from 'vue-chat-scroll';
Vue.use(VueChatScroll);
import moment from 'moment-timezone'
import 'moment/locale/ru'
        Vue.use(require('vue-moment'), {
            moment
        })
import VueSkycons from 'vue-skycons';
Vue.use(VueSkycons, {
    color: '#1e88e5'
});
import wysiwyg from "vue-wysiwyg";
Vue.use(wysiwyg, {}); // config is optional. more below

Vue.config.productionTip = false
Vue.use(Vuebar);
import axios from 'axios'
//import axios from 'axios'
import VueAxios from 'vue-axios'
        Vue.use(VueAxios, axios)
Vue.router = router


Vue.axios.defaults.baseURL = 'https://api.learn.mchs.ru/v2/'
   // Vue.use(VueNativeSock, 'wss://learn.mchs.ru/wss', {reconnection: true, store: store})
  //  Vue.axios.defaults.baseURL = 'https://api.learn.mchs.ru/v2/'


// if(process.env.NODE_ENV === 'development') {
//    Vue.axios.defaults.baseURL = 'http://api.learn.mchs.local/v2/'
//    Vue.use(VueNativeSock, 'ws://api.learn.mchs.local/wss', {reconnection: true, store: store})
//  } else {
//    Vue.axios.defaults.baseURL = 'https://learn.mchs.ru/api/v2/'
//    Vue.use(VueNativeSock, 'wss://learn.mchs.local/wss', {reconnection: true, store: store})
//  }
//

const VueUploadComponent = require('vue-upload-component')
Vue.component('file-upload', VueUploadComponent)
import VueObserveVisibility from 'vue-observe-visibility'

Vue.use(VueObserveVisibility)

import auth            from '@websanova/vue-auth/dist/v2/vue-auth.common.js';
import driverAuthBearer      from '@websanova/vue-auth/dist/drivers/auth/bearer.js';
import driverHttpAxios       from '@websanova/vue-auth/dist/drivers/http/axios.1.x.js';
import driverRouterVueRouter from '@websanova/vue-auth/dist/drivers/router/vue-router.2.x.js';
Vue.use(auth, {
    options:{
       authRedirect: '/authentication/login',  
    },
    plugins: {
        http: Vue.axios, // Axios
        // http: Vue.http, // Vue Resource
        router: Vue.router,
    },
    drivers: {
        auth: driverAuthBearer,
        http: driverHttpAxios, // Axios
        // http: driverHttpVueResource, // Vue Resource
        router: driverRouterVueRouter,
    }
})

import { VueMaskDirective } from 'v-mask'
        Vue.directive('mask', VueMaskDirective);
import {ServerTable} from 'vue-tables-2';
Vue.use(ServerTable, {}, false, 'bootstrap4');
Vue.filter('highlight', function(word, query){
  var check = new RegExp(query, "ig");
  return word.toString().replace(check, function(matchedText){
      return ('<strong>' + matchedText + '</strong>');
  });
});
Vue.filter('highlight2', function(word, query){
  var check = new RegExp(query, "ig");
  return word.toString().replace(check, function(matchedText,a,b){
      return ('<strong>'+a + matchedText +b+'</strong>');
  });
});

Vue.axios.interceptors.response.use(response => {
    return response;
}, (error) => {
    if (error.response) {
        switch (error.response.status) {
            // Вернуть 401, очистить информацию о токене и перейти на страницу входа
            case 401:
                localStorage.removeItem('token');
                Vue.router.replace({
                    path: '/authentication/login'
                            // Перейти на текущую страницу после успешного входа
                            // query: {redirect: router.currentRoute.fullPath}
                })

        }
        // Возвращаем сообщение об ошибке, возвращаемое интерфейсом
        return Promise.reject(error.response.data);
    }
});
new Vue({
    vuetify,
    store,
    router,
    render: h => h(App)
}).$mount('#app')

// http перехватчик ответа


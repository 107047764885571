import Vue from 'vue'
import Router from 'vue-router'
import goTo from 'vuetify/es5/services/goto'

        Vue.use(Router)

export default new Router({
mode: 'history',
        base: process.env.BASE_URL,
        // This is for the scroll top when click on any router link
        scrollBehavior: (to, from, savedPosition) => {
let scrollTo = 0

        if (to.hash) {
scrollTo = to.hash
} else if (savedPosition) {
scrollTo = savedPosition.y
}

return goTo(scrollTo)
},
        // This is for the scroll top when click on any router link
        routes: [
        {
        path: '/',
                component: () => import('@/layouts/full-layout/Layout'),
                meta: {auth: true},
                props: true,
                children: [
                        // Application
                        {
                        name: 'index',
                                path: '/',
                                component: () => import('@/views/profile/index'),
                        },
                        {
                        name: 'normative',
                        path: 'normative',
                        component: () => import('@/views/normative'),
                        },
                    {
                name: 'battle',
                        path: 'battle',
                        component: () => import('@/views/battle/index'),
                },
                {
                name: 'cords',
                        path: 'cords',
                        component: () => import('@/views/geo'),
                },
                {
                name: 'stations',
                        path: 'stations',
                        component: () => import('@/views/groups/stations'),
                },
                {
                name: 'editor',
                        path: 'editor',
                        component: () => import('@/views/courses/editorIndex'),
                },
                {
                name: 'groups',
                        path: 'groups',
                        component: () => import('@/views/groups/groups'),
                },
                {
                name: 'biblio',
                        path: 'biblio',
                        component: () => import('@/views/documents/biblio'),
                },
                {
                        name: 'plan-cons',
                                path: 'plan-cons',
                                component: () => import('@/views/documents/plan-cons'),
                        },
                {
                name: 'timetable',
                        path: 'timetable/:id',
                        component: () => import('@/views/groups/timetable'),
                },
                {
                name: 'video',
                        path: 'video',
                        component: () => import('@/views/video/index'),
                },
                {
                name: 'serviceArea',
                        path: 'service-area',
                        component: () => import('@/views/serviceArea/index'),
                },
                {
                name: 'reference',
                        path: 'reference',
                        component: () => import('@/views/reference/index'),
                },
                {
                name: 'course',
                        path: 'courses/:id/:mode?',
                        component: () => import('@/views/courses/course'),
                },
                {
                name: 'profile',
                        path: 'profile/:id?',
                        component: () => import('@/views/profile/index'),
                },
                {
                name: 'lesson',
                        path: 'lesson/:id/:mode?',
                        component: () => import('@/views/courses/lesson'),
                },
                        // Application
                        {
                        name: 'security',
                                path: 'user/security',
                                component: () => import('@/views/user/security'),
                        },
                        // Application
                        {
                        name: 'settings',
                                path: 'user/settings',
                                component: () => import('@/views/user/settings'),
                        },
                        // Application
                        {
                        name: 'documents',
                                path: 'documents',
                                component: () => import('@/views/documents/documents'),
                        },
                {
                path: '/admin',
                        name: 'Admin',
                        component: () => import(/* webpackChunkName: "about" */ './views/admin/Index.vue'),
                        meta: {auth: true},
                        children: [
                        {
                        path: '',
                                component: () => import(/* webpackChunkName: "about" */ './views/admin/users.vue')
                        },
                        {
                        path: 'users',
                                component: () => import(/* webpackChunkName: "about" */ './views/admin/users.vue')
                        },
                        {
                        path: 'mailing',
                                component: () => import(/* webpackChunkName: "about" */ './views/admin/mailing.vue')
                        }
                        ]
                },
                ]
        },
        {
        path: '/car',
                component: () => import('@/layouts/blank-layout/Blanklayout'),
                children: [
                {
                name: 'info',
                        path: 'info/:key',
                        component: () => import('@/views/car/info'),
                },
            {
                name: 'map',
                        path: 'map/:key',
                        component: () => import('@/views/car/map'),
                }]
        },
        {
        path: '/authentication',
                component: () => import('@/layouts/blank-layout/Blanklayout'),
                children: [
                {
                name: 'login',
                        path: 'login',
                        component: () => import('@/views/authentication/FullLogin'),
                },
                {
                name: 'register',
                        path: 'register',
                        component: () => import('@/views/authentication/FullRegister'),
                },
                {
                name: 'Error',
                        path: 'error',
                        component: () => import('@/views/authentication/Error'),
                },
                {
                name: 'Recovery',
                        path: 'recovery',
                        component: () => import('@/views/authentication/FullReset'),
                }
                ]
        },
        {
        path: '*',
                component: () => import('@/views/authentication/Error'),
        }
        ],
})
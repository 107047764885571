<template>
    <v-app id="materialpro" :class="`${!$vuetify.breakpoint.smAndDown ? 'full-sidebar' : 'mini-sidebar'}`">

        <loader ref="loader" v-if="renderComponent" @updateLoader='updateLoader()'/>
        <router-view :escButton="escButton"/>
        <v-overlay :value="chatWindow" ></v-overlay>
        <v-btn v-if="$auth.check()" @click="chatWindow=!chatWindow" fab  variant="primary"  size='120' color="primary" style="position: fixed; bottom:20px;right:20px; z-index:999999; " ripple><v-icon size='36'>mdi-forum-outline</v-icon><v-badge
                bottom :content="$auth.user().countNewMessages" v-if="$auth.user().countNewMessages>0" bordered class="pt-7" color="warning"
                ></v-badge></v-btn>
        <v-card v-if="$auth.check()" v-show="chatWindow" style="position:fixed; bottom:50px; right:50px; z-index:999998; width:800px;">
            <chat style="width:100%"/>
        </v-card>
        <v-snackbar
            v-model="$store.state.alertShow"
            >
            <v-row><v-col cols="auto"><v-avatar size="80"><img :src="$store.state.alert.ava" v-if="$store.state.alert.ava"/><v-icon size="60" v-else>mdi-account</v-icon></v-avatar></v-col>
                <v-col><h3 style="display:inline;">{{ $store.state.alert.name }}</h3>
                    <div>{{ $store.state.alert.text }}</div></v-col></v-row>

            <template v-slot:action="{ attrs }">
                <v-btn
                    color="pink"
                    text
                    v-bind="attrs"
                    @click="$store.state.alertShow = false"
                    >
                    Скрыть
                </v-btn>
            </template>
        </v-snackbar>
    </v-app>

</template>

<script>

    import chat from './views/chat/Chat'
    import loader from './views/loader'
    export default {
        name: 'App',

        components: {
            chat,
            loader
        },
        data() {
            return {
                chatWindow: false,
                renderComponent: true,
                escButton: {}
            }
        },
        methods: {
            updateLoader() {
                this.renderComponent = false;
                this.$nextTick(() => {
                    // Add the component back in
                    this.renderComponent = true;
                });

            }
        },
        created() {
            let that = this;
            document.addEventListener('keyup', function (evt) {
                if (evt.keyCode === 27) {
                    that.escButton = {id: Math.random(), action: 'esc'}
                }
            });

        },

    };
</script>

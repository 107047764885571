import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)
export default new Vuex.Store({
    state: {
        Sidebar_drawer: null,
        Customizer_drawer: false,
        SidebarColor: 'white', //Change Sidebar Color || 'white', | "#2b2b2b" | "rgb(44, 59, 164)" | "rgb(96, 44, 164)" | "rgb(151, 210, 219)" | "rgb(77, 86, 100)"
        SidebarBg: '',
        socket: {
            isConnected: false,
            message: '',
            reconnectError: false
        },
        lessonRoom: {
            isSpeaker:false,
            peerId:null,
            id:null,
            users: [],
            messages: []
        },
        data: {},
        messages: [],
        lastMessage: [],
        open_room_id: null,
        open_room: {},
        readRoom: {},
        alert: {},
        alertShow: false,
        encodedVideo: {}
    },
    mutations: {
        SOCKET_ONOPEN(state, event) {
            console.log(state)
            Vue.prototype.$socket = event.currentTarget
            state.socket.isConnected = true
            if(Vue.prototype.$auth.user()){
            Vue.prototype.$socket.send(JSON.stringify({'action': 'login', 'user_id': Vue.prototype.$auth.user().id, 'bearer': Vue.prototype.$auth.token()}))
            } else {
                Vue.prototype.$socket.send(JSON.stringify({'action': 'login'}))
            }
        },
        SOCKET_ONCLOSE(state, event) {
            console.log(event)
            state.socket.isConnected = false
        },
        SOCKET_ONERROR(state, event) {
            console.error(state, event)
        },
        // default handler called for all methods
        SOCKET_ONMESSAGE(state, message) {
            state.socket.message = message
            var resp = JSON.parse(message.data)
            if (resp.speech) {
                var speech = new Audio(resp.speech);
                speech.play();
            }
            if (resp.action === 'updatePeer') {
                if(state.lessonRoom.id==resp.roomId){
                    state.lessonRoom.peerId=resp.peerId
                }
            }
            if (resp.action === 'updateSpeaker') {
                if(state.lessonRoom.id==resp.roomId){
                    state.lessonRoom.isSpeaker=resp.value
                }
            }
            if (resp.action === 'lessonMessage') {
                if(state.lessonRoom.id==resp.roomId){
                    state.lessonRoom.messages.push({text:resp.text,user:resp.user})
                }
            }
            if (resp.action === 'lessonLeft') {
                if(state.lessonRoom.id==resp.roomId){
                    var index=state.lessonRoom.users.findIndex(el=>el.user_id==resp.userId)
                    if(index!==-1) {
                        state.lessonRoom.users.splice(index, 1)
                        state.lessonRoom.messages.push({text:resp.user.last_name+' '+resp.user.name +' '+resp.user.second_name+' покинул комнату',user:'system'})
                    }
                }
            }      
            if (resp.action === 'lessonUpdateUserList') {
                state.lessonRoom.id=resp.roomId
                state.lessonRoom.users=resp.users
                state.lessonRoom.messages=resp.messages
                state.lessonRoom.peerId=resp.peerId
            }
            if (resp.action === 'lessonJoinRoom') {
                state.lessonRoom.users.push(resp.user)
                state.lessonRoom.messages.push({text:resp.user.last_name+' '+resp.user.name +' '+resp.user.second_name+' зашёл в комнату',user:'system'})
            }
            
            if (resp.action === 'systemAlert') {
                state.alert.name = "Системное сообщение"
                state.alert.text = resp.text
                state.alertShow = true
                console.log(resp)
                if (resp.reload == true) {
                    setTimeout(function () {
                        location.reload(true);
                    }, resp.reloadTime * 1000);
                }
            }
            if (resp.action === 'groupAdd') {

                Vue.prototype.$auth.user().userGroups.push(resp.data)
            }
            if (resp.action === 'videoEncoded') {
                var audio1 = new Audio('https://scispace.hb.bizmrg.com/uploads/files/notify.ogg');
                audio1.play();
                state.alert.name = "Системное сообщение"
                state.alert.text = resp.text
                state.alertShow = true
                state.encodedVideo = resp.data
            }
            if (resp.action === 'groupRemove') {

                Vue.prototype.$auth.user().userGroups = []
            }
            if (resp['type'] === 'clarify') {
                if (resp['val'] === "+")
                {
                    if (!Vue.prototype.$auth.user()[resp['target']])
                    {
                        Vue.prototype.$auth.user()[resp['target']] = 1;
                        return
                    } else
                    {
                        Vue.prototype.$auth.user()[resp['target']] = Number(Vue.prototype.$auth.user()[resp['target']]) + 1
                        return
                    }
                }
                if (resp['val'] === '-')
                {
                    Vue.prototype.$auth.user()[resp['target']] = Number(Vue.prototype.$auth.user()[resp['target']]) - 1
                    return
                }
                if (resp['target'] === 'roomRead') {
                    state.readRoom = {...state.readRoom, id: resp['val']}
                }
                Vue.prototype.$auth.user()[resp['target']] = resp['val'];
                if (Vue.prototype.$auth.user()[resp['target']] == 0)
                {
                    Vue.prototype.$auth.user()[resp['target']] = 0
                }
            }

            if (resp['type'] === 'chat') {
                state.lastMessage = resp
                if (Number(resp['id']) === Number(state.open_room_id)) {
                    var data = resp['lastMessage']
                    data.user = resp.data
                    state.messages.push(data)
                } else {
                    Vue.prototype.$auth.user()['countNewMessages']++;
                    state.alert.text = resp['lastMessage']['text']
                    state.alert.ava = resp['data']['avatar']
                    state.alert.name = resp['data']['name']
                    state.alertShow = true
                }
                var audio = new Audio('https://scispace.hb.bizmrg.com/uploads/files/notify.ogg');
                audio.play();
                //$('#chatAudio')[0].play()
            }
        },
        // mutations for reconnect methods
        SOCKET_RECONNECT(state, count) {
            console.info(state, count)
        },
        SOCKET_RECONNECT_ERROR(state) {
            state.socket.reconnectError = true
        },
        SET_SIDEBAR_DRAWER(state, payload) {
            state.Sidebar_drawer = payload
        },
        SET_CUSTOMIZER_DRAWER(state, payload) {
            state.Customizer_drawer = payload
        },
        SET_SIDEBAR_COLOR(state, payload) {
            state.SidebarColor = payload
        },

    },
    actions: {
        sendMessage: function (context, message) {
            Vue.prototype.$socket.send(message)
        }
    },
    getters: {
        message_count(state) {
            return state.message_count
        },
        data: (state) => {
            return state.data;
        },
    }
})
import Vue from 'vue';


import Vuetify, {
    VRow,
    VCol,
    VTextField,
    VTooltip,
    VCheckbox,
    VSelect,
    VFileInput,
    VSwitch,
    VIcon,
    VBtn,
    VTextarea,
    VLabel,
    VList,
    VListItem,
    VListItemGroup,
    VListItemContent,
    VListItemIcon,
    VListItemTitle,
    VToolbar,
    VToolbarTitle,
    VSheet
} from 'vuetify/lib';
import '@/scss/vuetify/overrides.scss'
import "vue-wysiwyg/dist/vueWysiwyg.css";
import { Ripple, Intersect, Touch, Resize } from 'vuetify/lib/directives';

Vue.use(Vuetify, {
    components: { VRow, VTooltip, VCol, VTextField, VCheckbox, VSelect,VFileInput,VSwitch,VIcon,VBtn,VTextarea,VLabel,VListItemContent,VListItemIcon,VListItem,VListItemGroup,VList,VToolbar,VToolbarTitle,VListItemTitle,VSheet },
    directives: { Ripple, Intersect, Touch, Resize },
});


const theme = {
    primary: '#1e88e5', // change header color from here || "#1e88e6", "#21c1d6", "#fc4b6c", "#563dea", "#9C27b0", "#ff9800"
    info: '#1e88e5',
    success: '#21c1d6',
    accent: '#fc4b6c',
    default: '#563dea'
}

export default new Vuetify({
    theme: {
        themes: {
            dark: theme,
            light: theme,
        },
        dark: false,  // If you want to set dark theme then dark:true else set to false
    },
    rtl: false, // If you want to set rtl theme then rtl:true else set to false
});

<template>
    <div>
        <transition name="fade">
            <div style="z-index:99999; " class="superAnimation" ref="superAnimation" v-show="animationFirst">

                <div class='organism'>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                </div>
                 <div class='organism'>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                    <div class='atom'></div>
                </div>
            </div>
        </transition>
        <transition name="fade">
            <div  class="superAnimationSecond" v-show="animationSecond" style="  z-index:999999; display: flex;
                  align-items: center;
                  justify-content: center;">
                <div style="width: 100%; height: 50px;">
                    <center>
                        <img src="/img/gerb.png" ref="gerb" style="height: 500px; width:auto; top:50%; left: 50%; position: absolute; margin-top:-500px; margin-left:-229px;" class="object"/>
                    <h1 class="font-weight-thin grey--text text--lighten-4 text-elevetion object" ref="eios" style=" font-weight: 900 !important; top:50%; left: 50%; position: absolute; font-size: 120px; margin-left:-194px; margin-top: -70px;
                        ">ЭИОС</h1>
                        <p class="font-weight-thin grey--text text--lighten-4 " v-show="animationThird" ref="caption" style=" font-size: 40px; margin-top:100px;">Будущее пожарной охраны.</p>
                    </center>
                </div>
            </div>

        </transition>

    </div>
</template>
<style>
    .text-elevetion {

    text-shadow: 1px 1px 1px #919191,
        1px 2px 1px #919191,
        1px 3px 1px #919191,
        1px 4px 1px #919191,
        1px 5px 1px #919191,
        1px 6px 1px #919191,
        1px 7px 1px #919191,
        1px 8px 1px #919191,
        1px 9px 1px #919191,
        1px 10px 1px #919191,
    1px 18px 6px rgba(16,16,16,0.4),
    1px 22px 10px rgba(16,16,16,0.2),
    1px 25px 35px rgba(16,16,16,0.2),
    1px 30px 60px rgba(16,16,16,0.4);
}
    .object {
  
    transition: all 2s ease-in-out;
    -webkit-transition: all 2s ease-in-out; /** Chrome & Safari **/
    -moz-transition: all 2s ease-in-out; /** Firefox **/
    -o-transition: all 2s ease-in-out; /** Opera **/
}
    .fade-enter-active{
        transition: opacity .3s;
    }
    .fade-leave-active {
        transition: opacity .5s;
    }
    .fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
        opacity: 0;
    }
    .fly{
        -webkit-transform: scale(1000,1000);
-ms-transform: scale(1000,1000);
transform: scale(1000,1000);
transition: 10s;
    }
    .bounce-enter-active {
        animation: bounce-in 2s;
    }
    .bounce-leave-active {
        animation: bounce-in 2s reverse;
    }
    @keyframes bounce-in {
        0% {
            transform: scale(0);
        }
        50% {
            transform: scale(1.5);
        }
        100% {
            transform: scale(2);
        }
    }
    </style>
    <style lang="scss">
        $atom: 100;
        $speed: 3s;
        .superAnimationSecond{
            width: 100%;height: 100%;
            position: absolute;
            top:0px;
            left:0px;
        }
        .superAnimation{
            width: 100%;height: 100%;
            position: absolute;
            top:0px;
            left:0px;
        }
        .superAnimation{
            background-color: #1e88e5;
            overflow: hidden;
            margin:0;
            perspective: 1000px;
            *,*:after{
                position: absolute;top: 0;bottom: 0;left: 0;right: 0;margin: auto;border-radius: 50%;
            }
        }
        .organism{
            &:first-child{
                transform: scale(-1,-1)
            }
        }
        .atom{
            width: 100px;
            height: 100px;
            animation: life $speed ease-in-out infinite;
            &:after{
                content: "";
                width: inherit;
                height: inherit;
                border-bottom: 2px dotted transparent;
            }
            @for $i from 0 through $atom{
                &:nth-child(#{$i + 1}){
                    animation-delay: -$i * $speed / $atom;
                    &:after{
                        transform: rotateX($i * 720deg / $atom) translate($i * 500px / $atom) scale($i * 4 / $atom);
                        border-color: hsla(250 + ($i * 50 / $atom),50%,50%,1);
                    }
                }
            }
        }
        @keyframes life {
            50%{
                transform: rotateY(180deg) translateY(200px) scale(1,-1);
            }
        }
    </style>
    <script>

        export default {
            name: "index",
            data: () => ({

                    animationFirst: false,
                    animationSecond: false,
                    animationThird: false
                }),
            created() {

            },
            methods: {
                play() {
                    let that = this
                    that.animationFirst = true;
                    var speech
  
                this.$http.get('profile/get-speech-name').then((response) => {
                 speech = new Audio(response.data);

                speech.play();
    
                })
                
                
                var music = new Audio('https://api.eios-mchs.ru/spinning-whoosh_zjduubnu.mp3');
                music.play();
                setTimeout(function () {
                    that.animationSecond = true;
                    that.animationThird = true;
                    
            
                }, 1000);
  

                setTimeout(function () {
                    that.$refs.caption.classList.add('fly');
                      var left = that.$refs.gerb.getBoundingClientRect().left
                    left=left-document.getElementById("logo").getBoundingClientRect().left+10
                    left=left*-1
                    var top = that.$refs.gerb.getBoundingClientRect().top
                    top=top-document.getElementById("logo").getBoundingClientRect().top+16
                    top=top*-1
                    that.$refs.gerb.style['height']='86px';
                    that.$refs.gerb.style['transform']='translate('+left +'px,'+top+'px)';
                    that.$refs.gerb.style['-webkit-transform']='translate('+left+'px,'+top+'px)';
                    that.$refs.gerb.style['-o-transform']='translate('+left+'px,'+top+'px)';
                    that.$refs.gerb.style['-moz-transform']='translate('+left+'px,'+top+'px)';
                     left = that.$refs.eios.getBoundingClientRect().left-78
                     left=left*-1
                     top = that.$refs.eios.getBoundingClientRect().top-8
                     top=top*-1
                    that.$refs.eios.style['transform']='translate('+left +'px,'+top+'px)';
                    that.$refs.eios.style['-webkit-transform']='translate('+left+'px,'+top+'px)';
                    that.$refs.eios.style['-o-transform']='translate('+left+'px,'+top+'px)';
                    that.$refs.eios.style['-moz-transform']='translate('+left+'px,'+top+'px)';
                    that.$refs.eios.style['font-size']='36px';
                    that.$refs.eios.classList.remove('text-elevetion');


                    
                }, 2000);
                
                setTimeout(function () {
                    that.animationThird = false;
                    that.animationFirst = false;
                    that.animationSecond = false;
                    that.$emit('updateLoader')
                }, 4000);
                

                }
            }
        };
    </script>
<template>
    <v-container fluid class="chat-app common-left-right ma-0" >
        <v-row>
        <v-col cols="12" sm="12">
            <v-card class="d-flex flex-row " >
                <chat-rooms v-on:open-room="$store.state.open_room_id = $event"></chat-rooms>
                <!---/Left chat list -->
                <!---right chat conversation -->
                <chat-messages v-if="$store.state.open_room_id" :room_id="$store.state.open_room_id"/>
                        <template v-else>
            <div class="d-flex justify-center  align-center">
                <h4>Начать диалог</h4>
            </div>
        </template>    
                <!---right chat conversation -->
            </v-card>
            
        </v-col>
        </v-row>
    </v-container>    
</template>

<script>
import Vue from 'vue';
import UsersList from './userslist.js'
import ChatRooms from './ChatRooms'
import ChatMessages from './ChatMessages'
export default {
  name: 'Chat',
  components: {
      ChatRooms,
      ChatMessages
  },
  data:()=>({
      drawer:null, 
      doNotClose:true,
      handleSearchInput:"",
      UsersList: UsersList,
      chatUserActive: true,
      conversation: "",
      sendMessage:" ",
      isActive: false,
      windowWidth: window.innerWidth,
  }),
  methods: {
      selectedchat: function() {
      this.isActive = !this.isActive;
      // some code to filter users
    },
    openMessages(conversation) {
      Vue.set(this, 'conversation', conversation);
    },

    addMessage(e) {
      if (e.key === 'Enter' && e.target.value) {
        const value = {
          text: e.target.value,
          fromMe: true,
        };
        
        Vue.set(this, 'conversation', Object.assign({}, this.conversation, {
          messages: [
            ...this.conversation.messages || [],
            value,
          ],
        }));

        e.target.value = ''; 
        this.$scrollTo('#end', 1000, {container: "#chat",force: true})
      }
    },
    
    handleWindowResize(event) {
            this.windowWidth = event.currentTarget.innerWidth;
            this.setSidebarWidth();
    },
    setSidebarWidth() {
        if(this.windowWidth < 1170) {
            this.chatSidebarActive = this.doNotClose = false;
        }else {
            this.chatSidebarActive = this.doNotClose =  true;
        }
    },
    
  },
  computed:{
      filteredList() {
        return this.UsersList.filter(user => {
            return user.name.toLowerCase().includes(this.handleSearchInput.toLowerCase())
        }) 
    },
  },
  created() {
        this.$nextTick(() => {
            window.addEventListener('resize', this.handleWindowResize);
        })
        this.setSidebarWidth();
    },
    beforeDestroy: function () {
        window.removeEventListener('resize', this.handleWindowResize)
    },
  
}
</script>
<style lang="scss">
    .chat-app{
        overflow:hidden;
        .v-navigation-drawer__border{
            width:0px;
        }
        .chat-right-part{
            width: 100%;
            border-left:1px solid rgba(0,0,0,0.1);
            .chat-topbar{
                border-bottom: 1px solid rgba(0,0,0,0.1);
            }
        }
        .chat-room {
 
        .fromMe {
            -webkit-box-pack: start;
            -ms-flex-pack: start;
            justify-content: flex-start;
            -webkit-box-orient: horizontal;
            -webkit-box-direction: reverse;
            -ms-flex-direction: row-reverse;
            flex-direction: row-reverse;
        }
        }
        .hightauto{
            min-height: calc(100vh - 360px);
        }
    }
    
</style>
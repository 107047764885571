<template>
    <div class="chat-right-part">
        <template>
            <!---conversation header-->
            <div class="chat-topbar d-flex px-3 py-3 align-center">
                <div>
                    <v-app-bar-nav-icon @click.stop="drawer = !drawer" class="d-block d-lg-none mr-2" />
                </div>
                <v-avatar size="42" class="mr-3">
                    <img :src="ava(avatar)" width="37" v-if="ava(avatar)"/>
                    <v-icon size="37" v-else>mdi-account</v-icon>
                </v-avatar>
                <div class="user-about">
                    <h6>{{name}} {{last_name}}</h6>          <v-badge v-if="online" color="green" content="В сети"></v-badge>
                    <v-badge v-else content="Не в сети"></v-badge><br>
                    <v-badge v-if="room && room.data  && room.data.support" color="primary" >Техническая поддержка</v-badge> 
                </div>
            </div>
            <!---conversation header-->
            <!---Chat Room-->


                
                <div   style="overflow-y: scroll; height: 200px;" class="history">
                    
                    <div  class="chat-room px-3 py-3" v-chat-scroll="{always: true, smooth: true, scrollonremoved:true}">
                        <infinite-loading :on-infinite="infiniteChatMesages" :identifier="infiniteId" direction="top"><div slot="no-more"></div><div slot="no-results"></div></infinite-loading>
                        <div class="d-flex align-center mb-3" light v-for="message in $store.state.messages" :key="message.id" :class="{fromMe: message.from==$auth.user().id, 'messageItem': true,'grey lighten-2 rounded': message.read==0}">
                            <div class="thumb">
                                <span v-if="message.from==$auth.user().id">
                                    <v-avatar size="37" class="mx-2" >
                                        <img :src="$auth.user().profile.avatar" v-if="$auth.user().profile.avatar"/>
                                        <v-icon size="37" v-else>mdi-account</v-icon>
                                    </v-avatar>
                                    
                                </span>
                                <span v-else>
                                    <v-avatar size="37"  class="mx-2">
                                        <img :src="ava(message.user.avatar)" v-if="message.user && message.user.avatar"/>
                                        <v-icon size="37" v-else>mdi-account</v-icon>
                                    </v-avatar>
                                </span>
                            </div>
                            <v-card  :color="message.from==$auth.user().id ? 'primary': 'grey'" dark class="pa-1 caption white--text" v-html="convMessage(message.text,message.from)"></v-card>
                        </div> 
                        <span id="end"></span>
                    </div>
                </div>

            <!---Chat Room-->
            <div class="pa-1 border-top">
             
                <v-textarea no-resize autofocus name="input-4-1" rows="2" ref="newMessageArea" :disabled="sending" v-model="textArea" placeholder="Напишите и нижмите Enter"  @keydown="sendMessage" ></v-textarea>
            </div> 
        </template> 
  
    </div>
</template>
<style>
    .history::-webkit-scrollbar {
    background: #fff;
    min-width: 16px;
    width: 16px;
    max-width: 16px;
    min-height: 32px;
    height: 32px;
    max-height: 32px;
    }
    .history::-webkit-scrollbar-thumb {
    background: #3b8be8;
    border-radius: 100px;
    border: 3px solid #e5e5e5;
    box-shadow: inset 0 0 3px #999;
    }

</style>
<script>
    const api = 'chat/room'
var VueScrollTo = require('vue-scrollto');
import InfiniteLoading from 'vue-infinite-loading'
    export default {
        name: 'chat-messages',
        components: {
InfiniteLoading
        },
        directives: {

        },
        props: ['room_id'],
        data() {
            return {
                textArea:null,
                messages: [],
                sending:false,
                errors: [],
                busy: false,
                height: 0,
                loading: false,
                page: 1,
                room: null,
                infiniteId: +new Date(),
                users: [],
                avatar: [],
                name: null,
                last_name:null,
                online: false,
                status: '',
                no_group_ava: 'https://linksys.i.lithium.com/t5/image/serverpage/image-id/16800i3E8B46D91CD0CF26/image-size/original?v=1.0&px=-1'
            }
        },
        watch: {
            '$store.state.readRoom'() {
                if (this.room && this.room.id == this.$store.state.readRoom.id) {
                    for (var mes of this.$store.state.messages)
                    {
                        mes.read = 1
                    }
                }
            },
            '$store.state.lastMessage'() {
                if (this.room && this.room.id == this.$store.state.readRoom.id) {
                    VueScrollTo.scrollTo("#end", 1000, {container:'.history'})
                    for (var mes of this.$store.state.messages)
                    {
                        mes.read = 1
                    }
                }
            },
            // при изменениях маршрута запрашиваем данные снова
            'room_id': 'openRoom'
        },
        // Fetches posts when the component is created.
        methods: {
            nl2br(str, is_xhtml) {
                if (typeof str === 'undefined' || str === null) {
                    return '';
                }
                var breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br />' : '<br>';
                return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
            },
            ava(ava) {
                if (!ava)
                {
                    return null
                }
                return ava
            },
            back() {
                this.$store.state.open_room_id = false
                this.openRoom()
            },

            openRoom() {
                this.loading = true
                this.page = 1
                this.$store.state.messages = []
                this.infiniteId += 1;
                this.loading = false
            },
            convMessage(mes,from=false)
            {
                if(from && from==this.$auth.user().id){
                var res=mes.split('[/forTecher]')
               mes=res[0]
               if(res[1]){
                   mes=res[1]
               }
                } else {
                    mes=mes.replace('[/forTecher]', '');
                    mes=mes.replace('[forTecher]', '');
                }
                return this.nl2br(mes)
            },
            infiniteChatMesages($state) {
                if (this.loading === false) {
                    this.loading = true
                    var req_room = false
                    if (this.page == 1)
                    {
                        req_room = true //Запросить данные о чате
                    }
                    this.$http.get(api, {
                        params: {
                            id: this.room_id,
                            page: this.page,
                            chatData: req_room
                        }
                    }).then((response) => {
                        if (req_room === true && response.data.room && response.data.room.data)
                        {
                            var inf = response.data.room.data;
  
                            this.$set(this, 'avatar', inf.avatar)

                            this.$set(this, 'status', response.data.room.data.status)
                            this.$set(this, 'name', response.data.room.data.name)
                            this.$set(this, 'last_name', response.data.room.data.last_name)
                            this.$set(this, 'room', response.data.room)
                            this.$set(this, 'online', response.data.room.online)
                            this.$store.state.open_room = response.data.room
                        }
                        if (response.data.data.length) {
                            this.page += 1;

                            this.$store.state.messages.unshift(...response.data.data);
                            $state.loaded();
                            this.loading = false
                        } else {
                            $state.complete();
                        }

                    })
                }
            },
      sendMessage(e) {
          if (e.key === 'Enter' && this.textArea) {
              this.sending=true
        var text = this.textArea
        if (text) {
          this.$http.post('chat/message?room_id=' + this.$store.state.open_room_id, {
            'message': text // Axios
          })
                  .then(() => {
                    // JSON responses are automatically parsed.
                    // this.errors = response.data.errors
                    this.textArea=''
                  this.sending=false
                  this.$nextTick(() => {
                      this.$refs.newMessageArea.focus()
                  
              })
                  VueScrollTo.scrollTo("#end", 1000, {container:'.history'})
                  }).catch(e => {
                      this.sending=false
            console.log(e.response)
          })
        } else {
          alert('Напишите сообщение')
        }
      }
  }
        }
    }
</script>